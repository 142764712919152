import axios from 'axios';

export const fetchTitleExplore = async (
    batchIndex,
    titleLingo) => {
    const response = await axios.get(
        '/api/titleExplore', {
            params: {
                batchIndex: batchIndex,
                titleLingo: titleLingo
            },
            withCredentials: true
        });
    return response.data;
};
