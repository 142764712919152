import axios from 'axios';

export const fetchOwnerSubject = async (
    musicOwner) => {
    const response = await axios.get(
        '/api/ownerSubject', {
            params: {
                musicOwner: musicOwner
            },
            withCredentials: true
        });
    return response.data;
};
