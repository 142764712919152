import axios from 'axios';

export const writeVideoEmotion = async (
    videoKey,
    positive) => {
    const response = await axios.post(
        '/api/videoEmotion/' + (positive ? 'positive' : 'negative'), {}, {
            params: {
                musicVideo: videoKey
            },
            withCredentials: true
        });
    return response.data;
};
