import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import VideoSubject from "../components/VideoSubject";
import {Container} from "@mui/material";
import TitleSuggest from "../components/TitleSuggest";
import {fetchOwnerSubject} from "../api/OwnerSubject";
import Grid from "@mui/material/Unstable_Grid2";
import withWidth from "@mui/material/Hidden/withWidth";

const OwnerSubject = ({width}) => {
    const location = useLocation();
    const navigate = useNavigate();

    const [queryParam] = useSearchParams();

    const getMusicOwner = (location) => {
        return location.state && location.state.hasOwnProperty(
            'musicOwner') && location.state.musicOwner
                    ? location.state.musicOwner
                            : queryParam.get('o');
    }

    const getVideoBlock = (response) => {
        return response.videoSubject;
    }

    const [musicOwner, setMusicOwner] = useState(
        getMusicOwner(location));

    if (musicOwner !== getMusicOwner(location)) {
        setMusicOwner(getMusicOwner(location));
    }

    const [videoBlock, setVideoBlock] = useState([]);

    useEffect(() => {
        const updatePage = async (
                musicOwner) => {
            try {
                const response = await fetchOwnerSubject(
                    musicOwner
                );

                setVideoBlock(getVideoBlock(response));
            } catch (error) {
                if (error?.response?.status === 401) {
                    navigate('/lg')
                } else {
                    console.error(
                        "Error fetching owner subject: ", error);
                }
            }
        };

        updatePage(musicOwner).then();
    }, [musicOwner]);

    const onInputSelected = (video) => {
        let replace = 0;
        navigateToInput(video, replace);
    }

    const onVideoSelected = (video) => {
        let replace = 0;
        navigateToVideo(video, replace);
    }

    const onTitleSelected = (video) => {
        let replace = 0;
        navigateToTitle(video, replace);
    }

    const onOwnerSelected = (video) => {
        let replace = 0;
        navigateToOwner(video, replace);
    }

    const navigateToInput = (
        video, replace) => {
        let musicVideo = video;

        navigate(`/ts?v=${musicVideo}`,
            {
                state: {
                    musicVideo: musicVideo,
                },
                replace: replace
            });
    }

    const navigateToVideo = (
        video, replace) => {
        let musicVideo = video.videoKey;

        navigate(`/ts?v=${musicVideo}`,
            {
                state: {
                    musicVideo: musicVideo
                },
                replace: replace
            });
    }

    const navigateToTitle = (
        video, replace) => {
        let musicVideo = video.titleVideo;

        navigate(`/ts?v=${musicVideo}`,
            {
                state: {
                    musicVideo: musicVideo
                },
                replace: replace
            });

        window.scrollTo({top: 0, left: 0});
    }

    const navigateToOwner = (
        video, replace) => {
        let musicOwner = video.ownerKey;

        navigate(`/os?o=${musicOwner}`,
            {
                replace: replace
            });
    }

    const isSmallScreen = /xs/.test(width);

    return (
        <React.Fragment>
            <Container maxWidth='lg' disableGutters={isSmallScreen}>
                <TitleSuggest key={musicOwner} onInputSelected={onInputSelected} />

                <Grid container xs={12}>
                    {videoBlock?.map((video, index) => (
                        <Grid key={index} xs={12} sm={6} md={4} sx={{ px: {xs: 0, sm: 2}, py: {xs: 2} }}>
                            <VideoSubject
                                video={video}
                                onVideoSelected={onVideoSelected}
                                onTitleSelected={onTitleSelected}
                                onOwnerSelected={onOwnerSelected}
                            />
                        </Grid>
                    ))}
                </Grid>

            </Container>
        </React.Fragment>
    );
};

export default withWidth()(OwnerSubject);
