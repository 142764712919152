import React from 'react';
import ReactPlayer from "react-player/youtube";
import Grid from "@mui/material/Unstable_Grid2";
import {Avatar, Badge, IconButton, ToggleButton, ToggleButtonGroup, Typography} from "@mui/material";
import PlayPrevVideoIcon from '@mui/icons-material/SkipPrevious';
import PlayNextVideoIcon from '@mui/icons-material/SkipNext';
import {Favorite, FavoriteBorder, QueueMusic} from "@mui/icons-material";


const WatchSubject = (
    {
        video,
        title,
        onTitleSelected,
        onOwnerSelected,
        onVideoFinished,
        onPlayPrevVideo,
        onPlayNextVideo,
        onVideoFeedback
    }) => {

    return (
        <Grid container xs={12} sx={{ px: {xs: 0, md: 2}, pb: 2 }}>
            <Grid xs={12} style={{position: 'relative', paddingBottom: '56.25%'}}>
                <ReactPlayer
                    controls={true}
                    playing ={true}
                    loop={false}
                    onEnded ={() => {onVideoFinished(0)}}
                    onError ={() => {onVideoFinished(1)}}
                    url={'https://www.youtube.com/watch?v=' + video.videoKey}
                    style={{position: 'absolute', top: '0', left: '0'}}
                    width ='100%'
                    height='100%'
                />
            </Grid>

            <Grid container xs={12} sx={{ px: {xs: 1, md: 0}, pt: 1 }} alignItems='flex-start'>
                <Grid xs style={{cursor: 'pointer'}} onClick={() => onTitleSelected(video)}>
                    <Typography sx={{
                        overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical'}}>
                        <Typography component='span' sx={{ fontSize: { xs: 22, md: 28 }, fontWeight: 600 }}>
                            {video.trackTitle}
                        </Typography>

                        <Typography component='span' sx={{ fontSize: { xs: 22, md: 28 }, fontWeight: 200 }}
                                    whiteSpace='pre' color='#cccccc'>
                            {' ~ '}
                        </Typography>

                        <Typography component='span' sx={{ fontSize: { xs: 22, md: 28 }, fontWeight: 600 }}>
                            {video.actorTitle}
                        </Typography>
                    </Typography>
                </Grid>

                <Grid xs='auto' sx={{ pl: 2 }} xsOffset='auto'>
                        <Typography noWrap sx={{ fontSize: { xs: 22, md: 28 }, fontWeight: 600 }}>
                            {title.titleTotal}
                        </Typography>
                </Grid>

                <Grid xs='auto' sx={{ pl: 2 }} xsOffset='auto'>
                    <ToggleButtonGroup size='small'>
                        <ToggleButton value='0' sx={{ borderRadius: 28 }} onClick={() => {onPlayPrevVideo()}}>
                            <PlayPrevVideoIcon color='primary'/>
                        </ToggleButton>
                        <ToggleButton value='1' sx={{ borderRadius: 28 }}>
                            <QueueMusic        color='primary'/>
                        </ToggleButton>
                        <ToggleButton value='2' sx={{ borderRadius: 28 }} onClick={() => {onPlayNextVideo()}}>
                            <PlayNextVideoIcon color='primary'/>
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Grid>
            </Grid>

            <Grid container xs={12} sx={{ px: {xs: 1, md: 0}, pt: 1 }} alignItems='center'>
                <Grid xs='auto' sx={{pr: 1}}
                      style={{cursor: 'pointer'}} onClick={() => onOwnerSelected(video)}>
                    <Avatar src={video.ownerThumb[0]} alt={video.ownerTitle}/>
                </Grid>

                <Grid xs='auto' sx={{pr: 1}}
                      style={{cursor: 'pointer'}} onClick={() => onOwnerSelected(video)}>
                    {
                        video.ownerSaved !== 0 &&
                        <Badge badgeContent={video.ownerSaved} color='secondary' max={9}>
                            <Typography noWrap sx={{ fontSize: { xs: 22, md: 28 }, fontWeight: 600 }}>
                                {video.ownerTitle}
                            </Typography>
                        </Badge>
                    }

                    {
                        video.ownerSaved === 0 &&
                            <Typography noWrap sx={{ fontSize: { xs: 22, md: 28 }, fontWeight: 600 }}>
                                {video.ownerTitle}
                            </Typography>
                    }
                </Grid>

                <Grid xs='auto' xsOffset='auto'>
                    {
                        video.videoSaved === 0 &&
                        <IconButton color='secondary' size='large' onClick={() => onVideoFeedback(video, true )}>
                            <FavoriteBorder/>
                        </IconButton>
                    }

                    {
                        video.videoSaved !== 0 &&
                        <IconButton color='secondary' size='large' onClick={() => onVideoFeedback(video, false)}>
                            <Favorite/>
                        </IconButton>
                    }
                </Grid>
            </Grid>

        </Grid>
    );
};

export default WatchSubject;
