import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import OwnerSubject from './pages/OwnerSubject';
import LoginGateway from './pages/LoginGateway';
import TitleExplore from './pages/TitleExplore';
import TitleSubject from './pages/TitleSubject';
import {createTheme, ThemeProvider} from "@mui/material";

export const theme = createTheme({
    palette: {
        primary  : {
            main: '#3f51b5',
        },
        secondary: {
            main: '#f44336'
        },
    },
    typography: {
        button: {
            fontWeight: 600,
        },
    },
});

function App() {
  return (
      <ThemeProvider theme={theme}>
            <Router>
                <Routes>
                    <Route path="/"   element={<TitleExplore/>} />
                    <Route path="/lg" element={<LoginGateway/>} />
                    <Route path="/os" element={<OwnerSubject/>} />
                    <Route path="/ts" element={<TitleSubject/>} />
                </Routes>
            </Router>
      </ThemeProvider>
  );
}

export default App;
