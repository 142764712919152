import axios from 'axios';

export const fetchTitleSubject = async (
    musicVideo) => {
    const response = await axios.get(
        '/api/titleSubject', {
            params: {
                musicVideo: musicVideo
            },
            withCredentials: true
        });

    return response.data;
};
